import { useEffect } from "react";
import "./LoadingOverlay.css";

function LoadingOverlay() {
	useEffect(() => {
		const loadingOverlay = document.querySelector("#loadingOverlay");
		const loadingName = document.querySelector("#loadingName");
		const loadingText = document.querySelector("#loadingText");

		// Initial text scale animation
		loadingName.style.transform = "scaleX(1)";
		loadingText.style.transform = "scaleX(1)";

		// Fade text out after 950 milliseconds
		setTimeout(function () {
			loadingName.style.opacity = "0";
			loadingText.style.opacity = "0";
		}, 1350);

		// Wait 300 milliseconds to ensure everything is definitely loaded before revealing page
		setTimeout(function () {
			loadingOverlay.style.maxHeight = "0px";
		}, 900);
	}, []);

	return (
		<div id="loadingOverlay" className="loading-overlay text-center">
			<strong
				id="loadingName"
				className="pg text-5xl text-white font-normal pb-6"
			>
				Ben Foster
			</strong>
			<span id="loadingText" className="pg text-3xl pt-6">
				Code · Test · Debug
			</span>
		</div>
	);
}

export default LoadingOverlay;
