import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoadingOverlay from "./components/LoadingOverlay";
import NavBar from "./components/NavBar";
import SideMenu from "./components/SideMenu";
import Home from "./components/Home";

function App() {
	document.title = "Ben Foster | Web Developer";

	return (
		<BrowserRouter>
			<LoadingOverlay />
			<NavBar />
			<SideMenu />
			<Switch>
				<Route component={Home} path="/" exact />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
