import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";

function NavBar() {
	// useRef for conditional event handling (if isClickedRef.current {add event listener to root for click : menu close})
	const isClickedRef = useRef(false);
	// useState for menu icon changes (re-rendering)
	const [isClicked, setClicked] = useState(false);
	// Store root at runtime for later use
	const root = document.querySelector("#root");

	function menuClick() {
		isClickedRef.current = !isClickedRef.current;
		!isClicked ? openMenu() : closeMenu();
		setClicked(!isClicked);
	}

	function openMenu() {
		root.classList.remove("menu-close");
		root.classList.add("menu-open");
	}

	function closeMenu() {
		root.classList.remove("menu-open");
		root.classList.add("menu-close");
	}

	function rootClick(e) {
		// Ensure not clicking nav icon (would run menuClick twice causing bool to not change and menu not close)

		// REWRITE THIS MESS OF IF STATEMENTS
		if (e.target.nodeName !== "path" && e.target.nodeName !== "svg") {
			if (!e.target.classList.contains("side-menu-cont")) {
				if (!e.target.classList.contains("side-menu-link")) {
					if (!e.target.classList.contains("side-menu-bottom-2")) {
						if (!e.target.classList.contains("side-nav")) {
							if (
								!e.target.classList.contains(
									"side-menu-bottom-name"
								)
							) {
								if (!e.target.classList.contains("logo")) {
									if (
										!e.target.classList.contains("slogan")
									) {
										menuClick();
									}
								}
							}
						}
					}
				}
			}
		}
	}

	useEffect(() => {
		// Previous const root isn't available during initialization
		const el = document.querySelector("#root");
		if (isClickedRef.current) {
			el.addEventListener("click", rootClick, true);
			return () => el.removeEventListener("click", rootClick, true);
		}
	}, [isClicked]);

	return (
		<header className="fixed w-screen z-10 transition-all delay-200 duration-500 bg-niceBlue">
			<div className="px-6 md:px-16 lg:px-24 xl:px-32 2xl:px-36 mx-auto flex justify-between">
				<div className="flex items-center text-2xl text-white">
					<button
						aria-label="open-menu"
						className="flex items-center hover:cursor-pointer"
						id="open-menu"
						onClick={menuClick}
					>
						<FontAwesomeIcon
							icon={isClicked ? faTimes : faBars}
							className="text-3xl hover:scale-125"
						/>
					</button>
				</div>
				<nav className="flex">
					<a
						href="#home"
						className="pg inline-flex items-center py-3 md:py-6 text-white
						hover:text-gray-300 transition-all ease-in duration-500 delay-150 text-2xl font-light tracking-widest"
						id="header-name"
					>
						Ben Foster
					</a>
				</nav>
			</div>
		</header>
	);
}

export default NavBar;
