/* Library Imports */
import ReactFullpage from "@fullpage/react-fullpage";
/* Cookies Notice Import */
import CookieConsent from "react-cookie-consent";
/* Style Imports */
import "./Home.css";
/* Component Imports */
import HomeLanding from "./HomeLanding.js";
import HomePortfolio from "./HomePortfolio.js";
import HomeAbout from "./HomeAbout.js";
import HomeContact from "./HomeContact.js";

function Home() {
	return (
		<main className="home relative">
			<CookieConsent
				style={{ background: "rgb(34 41 87)" }}
				buttonStyle={{ background: "rgb(25 25 66)", color: "white" }}
			>
				This website uses cookies to enhance the user experience.{" "}
				<a
					href="/Ben_Foster_Privacy_Policy.pdf"
					download="Ben Foster Privacy Policy"
					className="underline"
				>
					Click here to download the privacy policy.
				</a>
			</CookieConsent>
			{/* Fullpage.js component: each Home[X] component contains .section */}
			<ReactFullpage
				licenseKey={"09A0158B-E31A4218-B2CD22F5-91E8934F"}
				anchors={["home", "portfolio", "about", "contact"]}
				scrollingSpeed={1000}
				easingcss3={"cubic-bezier(.65,.05,.35,1)"}
				navigation={true}
				navigationPosition={"right"}
				onLeave={(index, nextIndex, direction) => {
					switch (nextIndex.index) {
						case 0:
							document.title = "Ben Foster | Web Developer";
							break;
						case 1:
							document.title = "Ben Foster | Portfolio";
							break;
						case 2:
							document.title = "Ben Foster | About";
							break;
						case 3:
							document.title = "Ben Foster | Contact";
							break;
						default:
							break;
					}
				}}
				render={() => {
					return (
						<ReactFullpage.Wrapper>
							<HomeLanding />
							<HomePortfolio />
							<HomeAbout />
							<HomeContact />
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		</main>
	);
}
export default Home;
