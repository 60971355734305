import Particles from "react-particles-js";
import SkillBar from "react-skillbars";

const skills = [
	{ type: "HTML", level: 90 },
	{ type: "CSS", level: 90 },
	{ type: "JavaScript", level: 80 },
	{ type: "PHP", level: 70 },
	{ type: "SQL", level: 60 },
];

const skillColors = {
	bar: "white",
	title: {
		text: "white",
		background: "rgb(21 25 62)",
	},
	background: "rgb(21 25 62)",
};

function HomeAbout() {
	return (
		<section className="section home__about">
			<Particles
				style={{
					width: "100%",
					height: "100%",
					position: "absolute",
					zIndex: -1,
					opacity: 0.25,
				}}
				params={{
					particles: {
						number: {
							value: 50,
							density: {
								enable: true,
								value_area: 2000,
							},
						},
						move: {
							speed: 2,
						},
						size: {
							value: 5,
						},
						opacity: {
							value: 1,
						},
						line_linked: {
							opacity: 1,
							width: 3,
						},
					},
					interactivity: {
						detect_on: "window",
						events: {
							onhover: {
								enable: true,
								mode: "grab",
							},
							onclick: {
								enable: true,
								mode: "repulse",
							},
						},
						modes: {
							grab: {
								distance: 100,
								line_linked: {
									opacity: 0.9,
								},
							},
							repulse: {
								distance: 300,
							},
						},
					},
				}}
			/>
			<div className="container mx-auto h-full text-white">
				<div className="flex flex-col items-center">
					<h2 className="pg text-4xl md:text-3xl ws:text-5xl text-center uppercase tracking-wider font-light pt-3 lg:pt-8 ws:pt-12">
						About Me
					</h2>
					<span className="seperator border-white hidden md:block"></span>
				</div>
				<div className="w-full flex flex-col items-center mt-5">
					<div className="w-full max-w-2xl text-center text-base md:text-base ws:text-base font-normal tracking-wider md:tracking-wider md:leading-normal">
						<p>
							I am a web professional who enjoys turning a
							conceptual design into a practical and responsive
							webpage. I utilise the industry's cutting edge
							technology and best practices to deliver a unique
							and innovative website suitable for the client's
							needs.
						</p>
						<p className="mt-4">
							<span className="hidden md:inline">
								I graduated from the University of Worcester in
								2020 with a first class degree in Computing BSc
								(Hons).{" "}
							</span>
							I am proficient in HTML, CSS, JavaScript, jQuery,
							PHP and am practiced in using Content Management
							Systems and SEO optimisation.
						</p>
					</div>
					<div className="hidden ws:block w-full mt-10 max-w-2xl text-center">
						<SkillBar
							skills={skills}
							colors={skillColors}
							animationDelay={1000}
							height={40}
							offset={500}
						/>
					</div>
				</div>
				<a
					href="/Ben_Foster_CV.pdf"
					download="Ben Foster CV"
					className="download-cv select-none inline-block w-auto border-2 border-white mt-10 md:mt-14 ws:mt-8 py-3 md:py-2.5 px-6 md:px-4 rounded transition duration-400 bg-lighterBlue hover:bg-niceBlue"
				>
					Download my CV
				</a>
			</div>
		</section>
	);
}

export default HomeAbout;
