import { useState, useEffect } from "react";
import sanityClient from "../client.js";
//import bgPatternDark from "../assets/bg_pattern_dark.png";

function HomePortfolio() {
	const [portfolioData, setPortfolioData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "websites" && published == true] | order(order asc) {
					website_name,
					website_logo{
						asset->{
							_id,
							url
						},
						alt
					},
					website_url,
					published
				}`
			)
			.then((data) => setPortfolioData(data))
			.catch(console.error);
	}, []);

	return (
		<section className="section relative home__portfolio">
			{/*
			<img
				className="corner-img absolute right-0 bottom-0 transform -scale-x-1"
				src={bgPatternDark}
				alt="Corner border"
				draggable="false"
			/>
			*/}
			<div className="container mx-auto h-full">
				<div className="flex flex-col items-center">
					<h2 className="pg text-4xl md:text-3xl ws:text-5xl text-center uppercase tracking-wider font-light text-black pt-3 lg:pt-8 ws:pt-12">
						Portfolio
					</h2>
					<span className="seperator border-black hidden md:block"></span>
				</div>
				<div className="flex flex-col items-center">
					<p className="text-sm md:text-lg leading-5 md:leading-7 mt-5 font-medium text-center">
						A selection of websites I have worked on.
					</p>
					<small className="text-sm md:text-base mt-2 md:mt-1">
						Not the design - just the code!
					</small>
				</div>
				<div className="portfolio__grid grid grid-cols-3 md:grid-cols-4 gap-x-3 sm:gap-x-16 md:gap-x-24 lg:gap-x-48 ws:gap-x-36 gap-y-3 sm:gap-y-4 md:gap-y-8 ws:gap-y-8 mt-6 ws:mt-6">
					{portfolioData &&
						portfolioData.map((website, index) => (
							<div
								tabIndex="-1"
								className="portfolio__item"
								key={index}
							>
								<a
									href={website.website_url}
									alt={website.website_name}
									target="_blank"
									rel="noopener noreferrer"
									title={"Visit " + website.website_name}
								>
									<img
										className="w-full h-full object-contain"
										src={website.website_logo.asset.url}
										alt={website.website_name + " Logo"}
									/>
								</a>
							</div>
						))}
				</div>
			</div>
		</section>
	);
}

export default HomePortfolio;
