import { useEffect } from "react";
/* Library Imports */
import Parallax from "parallax-js";
/* Media Imports */
import ProfilePic from "../assets/profile-pic.jpeg";
import bg1 from "../assets/bg_parallax/bg1.png";
import bg2 from "../assets/bg_parallax/bg2.png";
import bg3 from "../assets/bg_parallax/bg3.jpg";

function HomeLanding() {
	useEffect(() => {
		/* Initialise parallax-js */
		var parallaxScene = document.getElementById("parallax");
		var parallaxInstance = new Parallax(parallaxScene);

		parallaxInstance.friction(0.15);

		const twEl = document.querySelector("#intro--tw");
		const text = "Web Developer";
		let y = 0;

		function twEffect() {
			if (y < text.length) {
				twEl.innerHTML += text.charAt(y);
				y++;
				if (!(y >= text.length)) {
					setTimeout(twEffect, 90);
				} else {
					// Hide typewriter blink effect after 3 seconds
					setTimeout(function () {
						twEl.style.overflow = "hidden";
					}, 3000);
				}
			}
		}
		setTimeout(function () {
			twEffect();
		}, 1400); // Wait 1000 milliseconds to ensure text is visible after page loading reveal
	}, []);

	return (
		<section className="section home__landing overflow-hidden">
			<div className="bg-parallax-width">
				<div
					id="parallax"
					className="bg-parallax-cont absolute left-0 top-0 w-full h-full"
					data-limit-y="1"
				>
					<img
						src={bg1}
						alt="Foreground mountains"
						className="absolute w-full h-full left-0 top-0 z-1"
						data-depth="0.4"
					/>
					<img
						src={bg2}
						alt="Middle mountains"
						className="absolute w-full h-full left-0 top-0 z-1"
						data-depth="0.15"
					/>
					<img
						src={bg3}
						alt="Background mountains and sky"
						className="absolute w-full h-full left-0 top-0 z-1"
						data-depth="0.05"
					/>
				</div>
			</div>
			<div className="relative z-10 flex flex-col justify-center items-center text-center text-white">
				<div className="profile-pic">
					<img
						src={ProfilePic}
						alt="Ben Foster"
						className="profile-pic__image rounded-full w-48 md:w-56 transform transition duration-300 p-1.5 border-white"
					/>
					<div className="profile-pic__circles">
						<div className="circles__circle"></div>
						<div className="circles__circle"></div>
						<div className="circles__circle"></div>
					</div>
				</div>
				<h1 className="pg text-4xl md:text-5xl uppercase tracking-wider mt-6 mb-3 font-light">
					Ben Foster
				</h1>
				<p
					id="intro--tw"
					className="biorhyme text-base md:text-xl font-light"
				></p>
			</div>
		</section>
	);
}

export default HomeLanding;
