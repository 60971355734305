import { useState, useEffect } from "react";

function HomeContact() {
	const [msgRows, setRows] = useState(6);
	const [formSubmitted, setSubmission] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [enquiry, setEnquiry] = useState("");
	const [message, setMessage] = useState("");

	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					"=" +
					encodeURIComponent(data[key])
			)
			.join("&");
	};

	const handleSubmit = (event) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": "contactForm",
				"contact-name": name,
				"contact-email": email,
				"contact-enquiry": enquiry,
				"contact-message": message,
			}),
		})
			.then((e) => {
				setSubmission(true);
			})
			.catch((error) => alert(error));

		event.preventDefault();
	};

	useEffect(() => {
		function handleResize() {
			let width = window.innerWidth;
			width <= 1024 && width > 768
				? setRows(8)
				: width <= 1281 && width > 1024
				? setRows(5)
				: width <= 768
				? setRows(8)
				: setRows(6);
		}

		handleResize();
		window.addEventListener("resize", handleResize);
	}, []);

	return (
		<section className="section home__contact">
			<div className="container mx-auto h-full text-black">
				<div className="flex flex-col items-center">
					<h2 className="pg text-4xl md:text-3xl ws:text-5xl text-center uppercase tracking-wider font-light text-black pt-3 lg:pt-8 ws:pt-12">
						Contact Me
					</h2>
					<span className="seperator border-black hidden md:block"></span>
				</div>
				<div className="w-full md:max-w-2xl mt-5 md:mt-10">
					<form
						className="bg-white shadow-lg rounded p-5 ws:p-10"
						name="contactForm"
						method="post"
						onSubmit={handleSubmit}
					>
						<input
							type="hidden"
							name="form-name"
							value="contactForm"
						/>
						<div className="mb-4">
							<input
								className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:shadow-md"
								type="text"
								name="contact-name"
								placeholder="Name"
								required
								disabled={formSubmitted}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<input
								className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:shadow-md"
								type="email"
								name="contact-email"
								placeholder="Email"
								required
								disabled={formSubmitted}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<select
								className="form-select block shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:shadow-md"
								name="contact-enquiry"
								required
								disabled={formSubmitted}
								defaultValue={"DEFAULT"}
								onChange={(e) => setEnquiry(e.target.value)}
							>
								<option value={"DEFAULT"} disabled hidden>
									Enquiry Subject
								</option>
								<option value="Quote">Receive a Quote</option>
								<option value="Support">Request Support</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div className="mb-6">
							<textarea
								className="form-textarea block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:shadow-md"
								type="textarea"
								name="contact-message"
								rows={msgRows}
								placeholder="Message"
								required
								disabled={formSubmitted}
								onChange={(e) => setMessage(e.target.value)}
							></textarea>
						</div>
						<div hidden>
							<input name="bot-field" />
						</div>
						<div className="flex items-center justify-center">
							<button
								className={`${
									formSubmitted
										? "bg-green-500"
										: "bg-niceBlue hover:bg-lighterBlue"
								}  transition duration-400 text-white font-bold py-2.5 px-16 rounded focus:outline-none focus:shadow-outline`}
								disabled={formSubmitted}
								type="submit"
							>
								{formSubmitted ? "Sent!" : "Send"}
							</button>
						</div>
					</form>
				</div>
				<span className="hidden xl:block pt-16 w-full max-w-2xl border-b-2 border-black opacity-50"></span>
				<div className="hidden xl:flex pt-14 w-full max-w-2xl flex-row justify-center items-center">
					<a
						href="#home"
						className="px-4 transition duration-400 hover:text-gray-600 uppercase"
					>
						Home
					</a>
					<a
						href="#portfolio"
						className="px-4 transition duration-400 hover:text-gray-600 uppercase"
					>
						Portfolio
					</a>
					<a
						href="#about"
						className="px-4 transition duration-400 hover:text-gray-600 uppercase"
					>
						About Me
					</a>
				</div>
			</div>
		</section>
	);
}

export default HomeContact;
