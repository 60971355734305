import "./SideMenu.css";
import Logo from "../assets/bf_logo.png";
import NavBottom from "../assets/nav_bottom.png";

function SideMenu() {
	return (
		<aside
			id="side-menu"
			className="fixed left-0 top-0 h-screen max-h-full bg-niceBlue z-10"
		>
			<div
				id="side-menu-cont"
				className="side-menu-cont w-full h-full flex flex-col justify-start items-center py-11 px-10 transition-all delay-200 duration-500"
			>
				<img className="logo w-1/4" src={Logo} alt="Ben Foster Logo" />
				<p className="slogan pg font-light text-center text-white mt-5 tracking-widest">
					Code · Test · Debug
				</p>
				<nav className="side-nav flex flex-col justify-top items-center mt-16">
					<a
						href="#home"
						className="side-menu-link pg inline-flex justify-center justify-center items-center text-white
						hover:text-gray-400 transition-all ease-in duration-300 text-2xl font-light tracking-widest"
					>
						Home
					</a>
					<a
						href="#portfolio"
						className="side-menu-link pg inline-flex justify-center items-center text-white
						hover:text-gray-400 transition-all ease-in duration-300 text-2xl font-light tracking-widest mt-6"
					>
						Portfolio
					</a>
					<a
						href="#about"
						className="side-menu-link pg inline-flex justify-center items-center text-white
						hover:text-gray-400 transition-all ease-in duration-300 text-2xl font-light tracking-widest mt-6"
					>
						About Me
					</a>
					<a
						href="#contact"
						className="side-menu-link pg inline-flex justify-center items-center text-white
						hover:text-gray-400 transition-all ease-in duration-300 text-2xl font-light tracking-widest mt-6"
					>
						Contact Me
					</a>
				</nav>
			</div>
			<img
				src={NavBottom}
				alt=""
				className="side-menu-bottom absolute left-0 w-full opacity-10 select-none"
				draggable="false"
			/>
			<img
				src={NavBottom}
				alt=""
				className="side-menu-bottom-2 absolute left-0 w-full opacity-5 select-none"
				draggable="false"
			/>
			<div className="side-copyright absolute left-0 bottom-0 w-full translate mb-1 flex justify-center items-center text-center">
				<small className="side-menu-bottom-name text-xs biorhyme text-white tracking-wider select-none">
					© {new Date().getFullYear()} Ben Foster
				</small>
			</div>
		</aside>
	);
}

export default SideMenu;
